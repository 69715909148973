import Layout from '@common/components/layout';
import Issue from '@universal/types/business/Issue';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import React, { FunctionComponent, ReactNode } from 'react';
import Notification from '@entities/notifications';
import CurrentTenantService from '@universal/services/currentTenant';
import useService from '@universal/behaviour/hooks/useService';
import Tenant from '@entities/tenants';
import DropDown from '@cComponents/dropDown';

import './web.css';
import SessionService from '@universal/services/session';
import { Avatar } from '@root/entities/users';
import ConnectedLogo from '@components/connectedLogo';

const SelectCurrentTenant: FunctionComponent = () => {
  const currentTenant = useService<CurrentTenantService>('currentTenant');
  switch(currentTenant.changeMode){
    case "unlimited": return (<Tenant.SelectCurrentTenant.Unlimited />);
    case "limited":   return (<Tenant.SelectCurrentTenant.Limited />);
    default: return null;
  }
}

const Help: FunctionComponent<{ children: ReactNode[] }> = ({ children }) => (
  <DropDown.List alignRight>
    <DropDown.List.Main>
      <img 
        alt="BetterStreet" 
        src={'/images/ico-help.png'} style={{ 
        width: "25px",
        height: "25px"
      }}/>
    </DropDown.List.Main>
    <DropDown.List.List>
    { children.map((child, index) => (<div key={ index }>{ child }</div>)) }
    </DropDown.List.List>
  </DropDown.List>
);

const Connexion: FunctionComponent<{ children: ReactNode[] }> = ({ children }) => {
  const session = useService<SessionService>('session');
  return (
    <DropDown.List alignRight>
      <DropDown.List.Main>
        <Avatar user={ session.user } />
      </DropDown.List.Main>
      <DropDown.List.List>
      { children.map((child, index) => (<div key={ index }>{ child }</div>)) }
      </DropDown.List.List>
    </DropDown.List>
  );
};

type WebProps = {
  navigationsItems: ReactNode[];
  helpItems: ReactNode[];
  connexionItems: ReactNode[];
  onIssueSelected: (issue: BusinessEntity<Issue>) => void;
};
const Web: FunctionComponent<WebProps> = ({ navigationsItems, helpItems, connexionItems, onIssueSelected }) => {
  return (
    <Layout.Columns className="bs-starter-header">
      <Layout.Columns.Column className="bs-starter-header-left">
        <ConnectedLogo withText/>
      </Layout.Columns.Column>
      <Layout.Columns.Column className="bs-starter-header-right">
        <div className="bs-starter-header-web">
          <div className="bs-starter-header-navigation">
            { navigationsItems }
          </div>
          <div className="bs-starter-header-other">
            <SelectCurrentTenant />
            <Notification onIssueSelected={ onIssueSelected } />
            <Help>
            { helpItems }
            </Help>
            <Connexion>
            { connexionItems }
            </Connexion>
          </div>
        </div>
      </Layout.Columns.Column>
    </Layout.Columns>
  );
}

export default Web;