import ObjectId from '../technic/ObjectId';
import Team from './Team';
import Tenant from './Tenant';
import File from './File';
import Building from './Building';

export enum UserDiscriminators {
  pro = "pro",
  citzen = "citizen",
  collaborator = "collaborator",
  tablet = "tablet",
  technical = "technical",
  betterstreet = "betterstreet"
};

export enum UserProEmailRoles {
  agent = "agent",
  admin = "admin",
  manager = "manager",
  categoryManager = "categoryManager",
  buildingIssuer = "buildingIssuer",
  publicSpaceIssuer = "publicSpaceIssuer",
  equipmentIssuer = "equipmentIssuer",
  publicSpaceViewer = "publicSpaceViewer",
  buildingViewer = "buildingViewer",
  equipmentViewer = "equipmentViewer",
  statistician = "statistician",
  starter = "starter",
};

export enum UserProUsernameRoles {
  agent = "agent",
};

export enum UserProAclAllowedRoles {
  agent = "agent",
  admin = "admin",
  manager = "manager",
  categoryManager = "categoryManager",
  teller = "teller",
  buildingIssuer = "buildingIssuer",
  equipmentIssuer = "equipmentIssuer",
  publicSpaceIssuer = "publicSpaceIssuer",
  viewer = "viewer",
  publicSpaceViewer = "publicSpaceViewer",
  buildingViewer = "buildingViewer",
  equipmentViewer = "equipmentViewer",
  statistician = "statistician",
  starter = "starter",
  tenantForbidPublicSpaceIssue = "tenantForbidPublicSpaceIssue",
  limitedToSettings = "limitedToSettings"
}
export type Channels = ("email" | "push")[];
export interface NotificationSubscription {
  name: string;
  channels: Channels
}

export type UserProRoles = UserProEmailRoles & UserProUsernameRoles;

export enum UserCollaboratorRoles {
  superAdmin = "superAdmin",
  appAdmin = "appAdmin",
  issueProvider = "issueProvider"
};

export interface AuthentificationProviderUsername {
  discriminator: "username",
  username: string
}

export type Roles = UserProRoles & UserCollaboratorRoles;

type User = UserPro
  | UserCollaborator
  | UserTablet
  | UserTechnical
  | UserBetterstreet
  | UserCitizen;

interface BaseUser {
  _id: ObjectId<BaseUser>;
  fullname: string;
}

export interface LoggableUser extends BaseUser {
  authentificationProviders: AuthentificationProviderUsername[]
}

export default User;

export interface RealPerson extends LoggableUser {
  firstname: string;
  lastname: string;
}

interface RealPersonUser extends RealPerson {
  personalSettings: { 
    subscriptions: NotificationSubscription[];
  };
  email?: string;
}

export type ConnectorGPJVSHorizon =  {
  entity: number;
  service: number;
  agent: object;
}

export type UserProTenant = {
  disabled: boolean;
  tenant: ObjectId<Tenant>;
  roles: UserProAclAllowedRoles[];
  canValorizeForOthers: boolean;
  connector: {
    gp: ConnectorGPJVSHorizon | null
  };
  tablet: {
    allowed: boolean;
  };
  buildings: {
    ids: ObjectId<Building>[],
    tags: string[]
  };
};

export interface UserPro extends RealPersonUser {
  discriminator: UserDiscriminators.pro;
  $hasValorization: boolean;
  valorization: number;
  team: ObjectId<Team>;
  tenants: UserProTenant[];
  avatar: ObjectId<File>;
}

export interface UserCitizen extends RealPersonUser {
  discriminator: UserDiscriminators.citzen;
}

export interface UserCollaborator extends RealPersonUser {
  discriminator: UserDiscriminators.collaborator;
  avatar: ObjectId<File>;
}

export interface UserTablet extends RealPerson {
  discriminator: UserDiscriminators.tablet;
  tenant: ObjectId<Tenant>;
}

export enum UserTechnicalApplication {
  attractiveCity = "jvs-ac",
  panneauPocket = "jvs-pp",
  gestionDeSalleJvs = "jvs-gs",
  grc = "jvs-grc"
}

export interface UserTechnical extends LoggableUser {
  discriminator: UserDiscriminators.technical;
  application: UserTechnicalApplication;
  tenant: ObjectId<Tenant>;
  createdIssueIsPublic: boolean;
}

export interface UserBetterstreet extends BaseUser {
  discriminator: UserDiscriminators.betterstreet;
}

export const isLoggableUser = (user: BaseUser): user is LoggableUser => !!(user as LoggableUser).authentificationProviders?.length;
export const isUserBetterstreet = (user: User): user is UserBetterstreet => user.discriminator === UserDiscriminators.betterstreet;
export const isUserTechnical = (user: User): user is UserTechnical => user.discriminator === UserDiscriminators.technical;
export const isUserTablet = (user: User): user is UserTablet => user.discriminator === UserDiscriminators.tablet;
export const isUserCollaborator = (user: User): user is UserCollaborator => user.discriminator === UserDiscriminators.collaborator;
export const isUserPro = (user: User): user is UserPro => user.discriminator === UserDiscriminators.pro;
export const isUserCitizen = (user: User): user is UserCitizen => user.discriminator === UserDiscriminators.citzen;
export const isRealPersonUser = (user: any): user is RealPersonUser => isUserPro(user) || isUserCollaborator(user) || isUserCitizen(user);
export const isRealPerson = (user: any): user is RealPerson => !!user.firstname && !!user.lastname;

export const isAgent = (user: User): boolean => isUserPro(user) && !!user.tenants?.some(t => t.roles.includes(UserProAclAllowedRoles.agent));