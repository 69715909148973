import Application from "@universal/lib/application";
import technicalLogin from "./TechnicalLogin";
import SessionService from "@universal/services/session";
import { TestSet } from "../library/test";
import createApplication, { StartHandler } from "../library/createApplication";
import StorageService from "@universal/services/types/storage";
import ConfigurationService from "@universal/services/configuration";
import getTechnicalUserPassword, { getApplicationLogAsGlobalTechnical, getApplicationLogAsTechnical, getGlobalTechnicalUserPassword, getUsernamePasswordForUser } from "../library/getTechnicalUserPassword";
import ACfindCategories from "./ACfindCategories";
import ACcreateIssue from "./ACcreateIssue";
import ACfindIssuesAndComment from "./ACfindIssuesAndComment";
import ACupdateIssueStateAndComment from "./ACupdateIssueStateAndComment";
import MockRequesterService from "../library/mockRequesterService";
import PPloginAsSuperUser from "./PPloginAsSuperUser";
import ApiService from "@universal/services/api";
import RequestBuilder from "@common/lib/http";
import PPactivateTenant from "../case/PPactivateTenant";
import PPActivateNotKnowedTenant from "./PPActivateNotKnowedTenant";
import PPcreateUserOnStarter from "./PPcreateUserOnStarter";
import Tenant from "@universal/types/business/Tenant";
import User, { LoggableUser } from "@universal/types/business/User";
import tenant from "@root/entities/tenants";
import PPcreateUserOnStarterAlreadyActivated from "./PPcreateUserOnStarterAlreadyActivated";
import PPcreateUserOnExpert from "./PPcreateUserOnExpert";
import PPcreateIssueWithEmailValidation from "./PPcreateIssueWithEmailValidation";
import PPcreateIssueWithoutEmailValidation from "./PPcreateIssueWithoutEmailValidation";
import PPgetCategories from "./PPgetCategories";
import PPgetIssues from "./PPgetIssues";


const createPPApiService = async (application: Application) => {
  const api = application.getService<ApiService>("api");
  api.create("tenants", "panneaupocketActivate", "/tenants/panneaupocket/activate", (httpBuilder: RequestBuilder) => {
    return httpBuilder.method("POST").type("application/json").call((body: object) => {
      return { body };
    });
  });
  api.create("tenants", "panneaupocketInfo", "/tenants/panneaupocket/info", (httpBuilder: RequestBuilder) => {
    return httpBuilder.method("POST").type("application/json").call((siren: string) => {
      return { body: { siren }};
    });
  });
  api.create("users", "panneaupocketCreate", "/users/panneaupocket", (httpBuilder: RequestBuilder) => {
    return httpBuilder.method("POST").type("application/json").call((body: object) => {
      return { body };
    });
  });
};




const connectAsPP =  (appTest: Application, confTest: ConfigurationService) => (getPPTechnicalUser: (appTest: Application) => Promise<{ user: LoggableUser, tenant: Tenant}>) => async () => {
  const { user, tenant } = await getPPTechnicalUser(appTest);

  const {
    application,
    username,
    password
  } = await getUsernamePasswordForUser(appTest, confTest, user, (async () => {}), createPPApiService);

  await application.getService<SessionService>("session").login(username, password);
  const appMainPP = await getApplicationLogAsGlobalTechnical(appTest, confTest, "jvs-pp-main", async () => {}, createPPApiService);
  return {
    appPP: application,
    appMainPP,
    appAdmin: appTest,
    tenant
  };
}

const createAppTest = async (application: Application, confTest: ConfigurationService) => {


  const appTest = await createApplication(confTest, async (appTest) => {
    appTest.initializeServices([
      new MockRequesterService()
    ]);

    const testStorage = appTest.getService<StorageService>("storage");
    const appStorage = application.getService<StorageService>("storage");
    const appSession = application.getService<SessionService>("session");
    const token = await appStorage.get(appSession.tokenName);
    if(!token){
      return;
    }
    testStorage.set(appSession.tokenName, token);
  });

  return appTest;
}

const buildTest = async (application: Application): Promise<TestSet> => {
  const set = new TestSet();
  const confService = application.getService<ConfigurationService>("configuration");
 
  if(confService.get("env") !== "development"){
    return set;
  }

  let confTest = confService.clone({
    api_uri: confService.get("api_uri_test")
  });
  let appTest = await createAppTest(application, confTest);

  set.setBeforeEach(async () => {
    confTest = confService.clone({
      api_uri: confService.get("api_uri_test")
    });
    appTest = await createAppTest(application, confTest);
  });

  set.setAfterEach(async () => {
    await appTest.getService<MockRequesterService>("mock").clear();
  });


  set.addTest(technicalLogin("AttractiveCity", getTechnicalUserPassword(appTest, confTest, "jvs-ac")));

  set.addTest(ACfindCategories(async () => {
    const appAc = await getApplicationLogAsTechnical(appTest, confTest, "jvs-ac");
    return {
      appAc,
      appAdmin: appTest
    };
  }));

  set.addTest(ACcreateIssue(async () => {
    const application = await getApplicationLogAsTechnical(appTest, confTest, "jvs-ac");
    return { application };
  }));

  set.addTest(ACfindIssuesAndComment(async () => {
    const appAc = await getApplicationLogAsTechnical(appTest, confTest, "jvs-ac");
    return {
      appAc,
      appAdmin: appTest
    };
  }));

  set.addTest(ACupdateIssueStateAndComment(async () => {
    const appAc = await getApplicationLogAsTechnical(appTest, confTest, "jvs-ac");
    return {
      appAc,
      appAdmin: appTest
    };
  }));

  set.addTest(PPloginAsSuperUser(getGlobalTechnicalUserPassword(appTest, confTest, "jvs-pp-main")));

  set.addTest(technicalLogin("PanneauPocket", getTechnicalUserPassword(appTest, confTest, "jvs-pp")));

  set.addTest(PPactivateTenant("Activation d'une structure non cliente", async () => {
    const appPP = await getApplicationLogAsGlobalTechnical(appTest, confTest, "jvs-pp-main", async () => {}, createPPApiService);
    return {
      appPP,
      appAdmin: appTest
    };
  }, null, "starter"));


  set.addTest(PPactivateTenant("Activation d'une structure cliente de type starter", async () => {
    const appPP = await getApplicationLogAsGlobalTechnical(appTest, confTest, "jvs-pp-main", async () => {}, createPPApiService);
    return {
      appPP,
      appAdmin: appTest
    };
  }, "starter", "starter"));


  set.addTest(PPactivateTenant("Activation d'une structure cliente de type experte", async () => {
    const appPP = await getApplicationLogAsGlobalTechnical(appTest, confTest, "jvs-pp-main", async () => {}, createPPApiService);
    return {
      appPP,
      appAdmin: appTest
    };
  }, "expert", "expert"));

  set.addTest(PPActivateNotKnowedTenant(async () => {
    const appPP = await getApplicationLogAsGlobalTechnical(appTest, confTest, "jvs-pp-main", async () => {}, createPPApiService);
    return {
      appPP,
      appAdmin: appTest
    };
  }));

  set.addTest(PPcreateUserOnStarter(connectAsPP(appTest, confTest), (beforeStart: StartHandler) => {
    return createApplication(confTest, beforeStart);
  }));

  set.addTest(PPcreateUserOnStarterAlreadyActivated(connectAsPP(appTest, confTest), (beforeStart: StartHandler) => {
    return createApplication(confTest, beforeStart);
  }));

  set.addTest(PPcreateUserOnExpert(connectAsPP(appTest, confTest)));

  set.addTest(PPgetCategories(async () => {
    const appPP = await getApplicationLogAsTechnical(appTest, confTest, "jvs-pp");
    return {
      appPP,
      appAdmin: appTest
    };
  }));

  set.addTest(PPgetIssues(async () => {
    const appPP = await getApplicationLogAsTechnical(appTest, confTest, "jvs-pp");
    return {
      appPP,
      appAdmin: appTest
    };
  }));

  set.addTest(PPcreateIssueWithEmailValidation(async () => {
    const appPP = await getApplicationLogAsTechnical(appTest, confTest, "jvs-pp");
    return {
      appPP,
      appAdmin: appTest
    };
  }, (beforeStart: StartHandler) => {
    return createApplication(confTest, beforeStart);
  }));

  set.addTest(PPcreateIssueWithoutEmailValidation(async () => {
    const appPP = await getApplicationLogAsTechnical(appTest, confTest, "jvs-pp");
    return {
      appPP,
      appAdmin: appTest,
      email: "",
      idRequestor: ""
    };
  }, (beforeStart: StartHandler) => {
    return createApplication(confTest, beforeStart);
  }));

  return set;
}

export default buildTest;